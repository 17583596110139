export const ApiError = (error) => {
  let errorMessage = "Something went wrong";

  if (error?.response) {
    // Check for specific status codes
    switch (error.response.status) {
      case 500:
        errorMessage = "Internal Server Error";
        break;
      case 404:
        errorMessage = "Data Not Found";
        break;
      case 401:
        errorMessage = "Unauthorized";
        break;
      case 403:
        errorMessage = "Forbidden";
        break;
      default:
        // Use the server-provided error message if available
        errorMessage = error.response?.data?.message || errorMessage;
        break;
    }
  } else if (error?.request) {
    // Request was made but no response was received
    errorMessage =
      "No response from server. Please check your network connection.";
  } else {
    // Something else went wrong while setting up the request
    errorMessage = error.message || errorMessage;
  }

  return errorMessage;
};
