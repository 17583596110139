import React, { useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomButton,
  Pagination,
  TableHeader,
} from "../common";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdOutlineFilterAlt } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { apiConnector } from "../../networking/ApiConnector";
import { superAdminEndPoints } from "../../networking/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/authSlice";
import useOnClickOutside from "../../helper/onClickOutside";
import Skeleton from "react-loading-skeleton";
import { ApiError } from "../../helper/ApiError";
import { toast } from "react-toastify";
const Company = () => {
  const ref = useRef(),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    { token, loading } = useSelector((state) => state.auth),
    [companies, setCompanies] = useState([]),
    [tableData, setTableData] = useState([]),
    [searchString, setSearchString] = useState(""),
    [companyCount, setCompanyCount] = useState(0),
    [openDropdownIndex, setOpenDropdownIndex] = useState(null),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [currentPage, setCurrentPage] = useState(1);

  const TableHeads = ["S.No", "Name", "Email", "Contact", "Address", "Actions"];

  useOnClickOutside(ref, () => setOpenDropdownIndex(null));

  const getAllCompanies = async () => {
    dispatch(setLoading(true));
    try {
      const companiesResponse = await apiConnector(
        "GET",
        superAdminEndPoints?.GET_ALL_COMPANIES_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_size: itemsPerPage,
          page_number: currentPage,
        }
      );
      setCompanies(companiesResponse?.data?.data?.companies);
      setTableData(companiesResponse?.data?.data?.companies);
      setCompanyCount(companiesResponse?.data?.data?.company_count);
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, [itemsPerPage, currentPage]);

  const filteredCompanies = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filterCompanies = companies?.filter(
        (item) =>
          item?.company_name.toLowerCase().includes(val.toLowerCase()) ||
          item?.company_email.toLowerCase() === val.toLowerCase() ||
          item?.company_contact_no.toString() === val.toString() ||
          item?.company_address.toLowerCase() === val.toLowerCase()
      );
      setTableData(filterCompanies);
    } else {
      setTableData(companies);
    }
  };

  const pageCount = Math.ceil(companyCount / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  return (
    <div className="w-full">
      <section className="flex sm:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-bold text-lg">Company List</h1>
        <div className="flex sm:flex-row flex-col justify-center items-center gap-4">
          <div className="relative">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              type="text"
              placeholder="Search"
              onChange={(e) => filteredCompanies(e)}
              value={searchString}
              className="border px-6 py-2 outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={<MdOutlineFilterAlt />}
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 rounded-md border "
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add Company</span>
              </div>
            }
            buttonType="button"
            onClick={() => navigate("/company/create")}
            classname="bg-gradient-custom text-custom-white px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full">
        <table className="w-full border rounded-lg bg-custom-white overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : tableData && tableData?.length > 0 ? (
            <tbody>
              {tableData?.map((item, index) => {
                return (
                  <tr key={index} className="border-b">
                    <td className="p-3 text-sm w-5 text-center font-medium">
                      {index + 1}
                    </td>
                    <td className="p-3 text-sm truncate max-w-40">
                      {item?.company_name}
                    </td>
                    <td className="p-3 text-sm">{item?.company_email}</td>
                    <td className="p-3 text-sm">{item?.company_contact_no}</td>
                    <td className="p-3 text-sm truncate max-w-52">
                      {item?.company_address}
                    </td>
                    <td
                      onClick={() =>
                        setOpenDropdownIndex(
                          openDropdownIndex === index ? null : index
                        )
                      }
                      className="p-3 text-sm flex justify-center items-center cursor-pointer"
                    >
                      <HiOutlineDotsVertical />
                      {openDropdownIndex === index && (
                        <CustomActionDropDown ref={ref} />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm"
                >
                  Data Not Found
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                Showing{" "}
                {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}{" "}
                to {(currentPage - 1) * itemsPerPage + companies?.length} from{" "}
                {companyCount} entries
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>
  );
};

export default Company;
