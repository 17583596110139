import React, { useEffect, useState } from "react";
import TextEditor from "./TextEditor";
import { setLoading } from "../../redux/slice/authSlice";
import { ApiError } from "../../helper/ApiError";
import { apiConnector } from "../../networking/ApiConnector";
import { useDispatch, useSelector } from "react-redux";
import { commonEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";
import {
  dateFormatter,
  groupByDate,
  timeFormatter,
} from "../../helper/formatDate";
import { BiEditAlt } from "react-icons/bi";

const baseUrl = process.env.REACT_APP_BASE_URL;
const Comments = ({ taskId }) => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const [editComment, setEditComment] = useState(null);
  const [comments, setComments] = useState(null);

  const fetchComments = async () => {
    dispatch(setLoading(true));
    try {
      const comment_response = await apiConnector(
        "GET",
        `${commonEndPoints?.COMMENT_API}/${taskId}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setComments(comment_response?.data?.data?.comments);
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchComments();
  }, [taskId]);

  const groupedComments = groupByDate(comments ?? []);

  return (
    <div className="relative w-full h-full">
      <section className="w-full h-[calc(100%-300px)] p-2 overflow-auto">
        {Object?.entries(groupedComments)?.length > 0 ? (
          Object?.entries(groupedComments)?.map(([date, notes]) => (
            <div key={date}>
              <h2 className="font-normal flex items-center my-2">
                <span className="flex-grow border-t border-[#EFE9CF] mr-3"></span>
                {date}
                <span className="flex-grow border-t border-[#EFE9CF] ml-3"></span>
              </h2>
              {notes?.map((item, index) => (
                <div
                  key={item?._id}
                  className={`${
                    notes?.length - 1 !== index && "border-b border-[#EFE9CF]"
                  }  w-full py-2 space-y-2 group`}
                >
                  <div className="flex justify-between items-center gap-2 ">
                    <div className="flex items-center gap-4">
                      <img
                        src={
                          item?.author?.avatar
                            ? `${baseUrl}/${item?.author?.avatar}`
                            : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.author?.user_name}`
                        }
                        alt={item?.author?.user_name || "image"}
                        loading="lazy"
                        className="w-7 h-7 rounded-full"
                      />
                      <span className="text-custom-black text-sm font-semibold">
                        {item?.author?._id === user?._id
                          ? "You"
                          : item?.author?.user_name}
                      </span>
                      <span className="text-xs text-gray-400 ">
                        {item?.createdAt && (
                          <>
                            {dateFormatter(item?.createdAt)}{" "}
                            {timeFormatter(item?.createdAt)}
                            {item?.createdAt.split(".")[0] !==
                              item?.updatedAt.split(".")[0] && " | "}
                            {item?.createdAt.split(".")[0] !==
                              item?.updatedAt.split(".")[0] && (
                              <>
                                Edited {dateFormatter(item?.updatedAt)}{" "}
                                {timeFormatter(item?.updatedAt)}
                              </>
                            )}
                          </>
                        )}
                      </span>
                    </div>

                    {item?.author?._id === user?._id && (
                      <span
                        onClick={(event) => {
                          event.stopPropagation();
                          setEditComment(item);
                        }}
                        className="text-gray-400 w-8 h-8 rounded-full flex justify-center items-center bg-[#F2F2F2] opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                      >
                        <BiEditAlt size={21} />
                      </span>
                    )}
                  </div>
                  <p
                    className="text-sm text-justify text-slate-600"
                    dangerouslySetInnerHTML={{ __html: item?.content }}
                  ></p>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="text-center font-semibold text-sm ">
            No notes available
          </div>
        )}
      </section>
      <section className="w-full absolute bottom-12">
        <TextEditor
          taskId={taskId}
          fetchComments={fetchComments}
          editComment={editComment}
          setEditComment={setEditComment}
        />
      </section>
    </div>
  );
};

export default Comments;
