import React, { useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomButton,
  Pagination,
  TableHeader,
} from "../common";
import { IoMdSearch } from "react-icons/io";
import { MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../../helper/onClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import Skeleton from "react-loading-skeleton";
import { ApiError } from "../../helper/ApiError";
import { Roles } from "../../networking/Constant";
import { toast } from "react-toastify";

const Employees = () => {
  const ref = useRef(),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    { token, user } = useSelector((state) => state.auth),
    { employeeLoading } = useSelector((state) => state.employee),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [currentPage, setCurrentPage] = useState(1),
    [employeeCount, SetEmployeeCount] = useState(0),
    [searchString, setSearchString] = useState(""),
    [employees, setEmployees] = useState([]),
    [tableData, setTableData] = useState([]),
    [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const TableHeads = [
    "Employee ID",
    "Name",
    "Email",
    "Contact Number",
    "Department",
    "Designation",
    "Actions",
  ];

  useOnClickOutside(ref, () => setOpenDropdownIndex(null));

  const fetchEmployees = async () => {
    try {
      const response = await dispatch(
        fetchAllEmployees({ token, currentPage, itemsPerPage })
      ).unwrap();

      if (response?.data) {
        setEmployees(response.data.employees);
        setTableData(response.data.employees);
        SetEmployeeCount(response.data.employee_count);
      }
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [currentPage, itemsPerPage]);

  const filterEmployee = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filterCompanies = employees?.filter(
        (item) =>
          item?.user_name?.toLowerCase().includes(val.toLowerCase()) ||
          item?.email?.toLowerCase() === val.toLowerCase() ||
          item?.mobile_number?.toString().includes(val.toLowerCase()) ||
          item?.department?.department_name
            ?.toLowerCase()
            .includes(val.toLowerCase()) ||
          item?.designation?.designation
            ?.toLowerCase()
            .includes(val.toLowerCase())
      );
      setTableData(filterCompanies);
    } else {
      setTableData(employees);
    }
  };
  const pageCount = Math.ceil(employeeCount / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  return (
    <div className="w-full ">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold">Employee List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          <div className="relative ">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              label=""
              type="text"
              placeholder="Search"
              onChange={(e) => filterEmployee(e)}
              value={searchString}
              className="bg-custom-white px-6 py-2 border outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={<MdOutlineFilterAlt />}
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 rounded-md border"
          />
          {user?.roleId !== Roles?.employee && (
            <CustomButton
              title={
                <div className="flex justify-center items-center gap-2 ">
                  <FaPlusCircle />
                  <span>Add Employee</span>
                </div>
              }
              buttonType="button"
              onClick={() => navigate("/employees/create")}
              classname="bg-gradient-custom text-custom-white px-5 py-2 rounded-md"
            />
          )}
        </div>
      </section>
      <section className="w-full">
        <table className="w-full border rounded-lg bg-custom-white overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {employeeLoading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : tableData && tableData?.length > 0 ? (
            <tbody>
              {tableData?.map((item, index) => {
                return (
                  <tr key={index} className="border-b">
                    <td className="p-3 text-sm">{item?.emp_id}</td>
                    <td className="p-3 text-sm">{item?.user_name}</td>
                    <td className="p-3 text-sm">{item?.email}</td>
                    <td className="p-3 text-sm">{item?.mobile_number}</td>
                    <td className="p-3 text-sm">
                      {item?.department?.department_name ?? "-"}
                    </td>
                    <td className="p-3 text-sm">
                      {item?.designation?.designation ?? "-"}
                    </td>
                    <td
                      onClick={() =>
                        setOpenDropdownIndex(
                          openDropdownIndex === index ? null : index
                        )
                      }
                      className="p-3 flex justify-center items-center cursor-pointer"
                    >
                      <HiOutlineDotsVertical />
                      {openDropdownIndex === index && (
                        <CustomActionDropDown
                          ref={ref}
                          buttonsDropdown={[
                            {
                              id: 1,
                              name: "View",
                              onClick: () =>
                                navigate("/employees/view", {
                                  state: { id: item?._id },
                                }),
                            },
                            {
                              id: 2,
                              name: "Edit",
                              onClick: () => {},
                            },
                            {
                              id: 3,
                              name: "Delete",
                              onClick: () => {},
                            },
                          ]}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm"
                >
                  Data Not Found
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                Showing{" "}
                {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}{" "}
                to {(currentPage - 1) * itemsPerPage + employees?.length} from{" "}
                {employeeCount} entries
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>
  );
};

export default Employees;
