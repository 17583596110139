import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProjectViewSkeleton = () => {
  return (
    <div className="w-full h-full p-6 rounded-md flex justify-center gap-4 space-y-2 bg-custom-white">
      <div className="w-[70%] md:space-y-10">
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 gap-y-20">
          {Array(7)
            .fill("")
            .map((_, index) => (
              <ProjectHeadingSkeleton key={index} />
            ))}
        </div>
        <ProjectHeadingSkeleton description />
      </div>
      <div className="w-[30%] h-full bg-[#FEF8DC] rounded-lg">
        <div className="border-b border-dashed p-4 rounded-t-lg bg-[#FFF5BD]">
          <h1 className="text-custom-black">
            <Skeleton width={"50%"} />
          </h1>
        </div>
        <div className="p-4 space-y-4">
          {Array(3)
            .fill("")
            .map((_, index) => (
              <div key={index} className="space-y-2">
                <div className="flex justify-between items-center gap-2">
                  <Skeleton circle width={20} height={20} />
                  <Skeleton width={"40%"} />
                </div>
                <Skeleton count={2} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const ProjectHeadingSkeleton = ({ description = false }) => {
  return (
    <div className="flex flex-col gap-1">
      <Skeleton width={"50%"} />
      <Skeleton
        width={description ? "100%" : "75%"}
        count={description ? 4 : 1}
      />
    </div>
  );
};

export default ProjectViewSkeleton;
