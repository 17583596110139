import React, { useEffect, useState } from "react";
import { getAllPersonalTask } from "../../redux/slice/taskSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApiError } from "../../helper/ApiError";
import CommonTaskTable from "./CommonTaskTable";

const PersonalTask = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [taskCount, setTaskCount] = useState(0);

  const fetchPersonalTask = async () => {
    try {
      const response = await dispatch(
        getAllPersonalTask({ token, currentPage, itemsPerPage })
      ).unwrap();
      if (response?.data) {
        setTasks(response?.data?.tasks);
        setTableData(response?.data?.tasks);
        setTaskCount(response?.data?.task_count);
      }
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    }
  };
  useEffect(() => {
    fetchPersonalTask();
  }, [currentPage, itemsPerPage]);

  return (
    <>
      <CommonTaskTable
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setItemsPerPage}
        tasks={tasks}
        tableData={tableData}
        taskCount={taskCount}
        fetchAllTasks={fetchPersonalTask}
      />
    </>
  );
};

export default PersonalTask;
