import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

const CustomSidebar = ({ menuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const matchRoute = (item) => {
    if (!item) return false;
    const { path, active } = item;

    const isPathMatch = path ? matchPath(path, location?.pathname) : false;
    const isActiveMatch = active
      ? active.some((activePath) => matchPath(activePath, location?.pathname))
      : false;

    return isPathMatch || isActiveMatch;
  };

  return (
    <div
      className={`w-64 h-full border-r shadow-md bg-custom-white absolute z-30 lg:static transition-transform transform duration-500 delay-300 lg:transform-none`}
    >
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={`${
            matchRoute(item) &&
            "text-custom-blue bg-[#E3EFF8] border-l-4 border-custom-blue"
          } flex items-center gap-4 px-5 py-3 font-medium cursor-pointer`}
          onClick={() => item?.path && navigate(item.path)}
        >
          <div className="text-xl">{item.icon}</div>
          <span>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomSidebar;
