import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CustomSidebar } from "../components/common";
import {
  AiOutlineCheckSquare,
  AiOutlineProject,
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineFile,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import { FaRegBuilding } from "react-icons/fa";
import { Roles } from "../networking/Constant";
import { MdOutlineDashboard, MdOutlineLocationOn } from "react-icons/md";
import { PiUsersThree } from "react-icons/pi";
import BreadCrumb from "../components/common/BredCrumb";
import { BiUserPin } from "react-icons/bi";

const Home = ({ showSidebar }) => {
  const { user } = useSelector((state) => state.auth);

  let menuItems = [];
  if (user?.roleId === Roles?.super_admin) {
    menuItems = [
      {
        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },
      {
        name: "Company",
        path: "/company",
        active: ["company", "/company/create"],
        icon: <FaRegBuilding />,
      },
    ];
  } else if (user?.roleId === Roles?.admin) {
    menuItems = [
      {
        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },
      {
        name: "Employees",
        path: "/employees",
        active: ["employees", "/employees/create", "/employees/view"],
        icon: <AiOutlineUser />,
      },
      {
        name: "Department",
        path: "/department",
        active: ["department"],
        icon: <PiUsersThree />,
      },
      {
        name: "Designation",
        path: "/designation",
        active: ["designation"],
        icon: <BiUserPin />,
      },
      {
        name: "Office Location",
        path: "/office_location",
        active: ["office_location"],
        icon: <MdOutlineLocationOn />,
      },

      {
        name: "Project",
        path: "/project",
        active: ["/project", "/project/create", "/project/view"],
        icon: <AiOutlineProject />,
      },
      {
        name: "Task",
        path: "/task",
        active: ["task", "/task/create", "/task/view"],
        icon: <AiOutlineCheckSquare />,
      },
    ];
  } else if (user?.roleId === Roles?.project_manager) {
    menuItems = [
      {
        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },
      {
        name: "Employees",
        path: "/employees",
        active: ["employees", "/employees/create", "/employees/view"],
        icon: <AiOutlineUser />,
      },
      {
        name: "Project",
        path: "/project",
        active: ["project", "/project/create", "/project/view"],
        icon: <AiOutlineProject />,
      },
      {
        name: "Task",
        path: "/task",
        active: ["task", "/task/create", "/task/view"],
        icon: <AiOutlineCheckSquare />,
      },
      {
        name: "Calendar",
        path: "/calender",
        active: ["calender"],
        icon: <AiOutlineCalendar />,
      },
      {
        name: "Timesheets",
        path: "/timesheets",
        active: ["timesheets"],
        icon: <AiOutlineClockCircle />,
      },
      {
        name: "Report",
        path: "/report",
        active: ["report"],
        icon: <AiOutlineFile />,
      },
      // {
      //   name: "Invite Teammates",
      //   active: ["teammates"],
      //   icon: <AiOutlineUserAdd />,
      // },
    ];
  } else if (user?.roleId === Roles?.employee) {
    menuItems = [
      {
        name: "Dashboard",
        path: "/dashboard",
        active: ["dashboard"],
        icon: <MdOutlineDashboard />,
      },
      {
        name: "Employees",
        path: "/employees",
        active: ["employees", "/employees/create", "/employees/view"],
        icon: <AiOutlineUser />,
      },
      {
        name: "Project",
        path: "/project",
        active: ["/project", "/project/create", "/project/view"],
        icon: <AiOutlineProject />,
      },
      {
        name: "Task",
        path: "/task",
        active: ["task", "/task/create", "/task/view"],
        icon: <AiOutlineCheckSquare />,
      },
      // {
      //   name: "Performance",
      //   path: "/performance",
      //   active: ["performance"],
      //   icon: <PiUsersThreeLight />,
      // },
      {
        name: "Report",
        path: "/report",
        active: ["report"],
        icon: <AiOutlineFile />,
      },
    ];
  } else {
    <Navigate to={"/login"} />;
  }
  return (
    <div className="flex w-full">
      <div
        className={`${
          showSidebar ? "hidden" : " block"
        } lg:block transition-all duration-500 ease-linear delay-300`}
      >
        <CustomSidebar menuItems={menuItems} />
      </div>

      <div
        className={`w-full h-full md:h-[calc(100vh-64px)]  p-2 sm:p-6 bg-custom-light-blue overflow-x-hidden`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
