import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CustomButton, CustomInput, CustomSelect } from "../common";
import { MdOutlineEventNote } from "react-icons/md";
import * as yup from "yup";
import { apiConnector } from "../../networking/ApiConnector";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { commonEndPoints } from "../../networking/Endpoints";
import { fetchDepartment } from "../../redux/slice/departmentSlice";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import { Roles, statusOptions } from "../../networking/Constant";
import { ApiError } from "../../helper/ApiError";

const baseUrl = process.env.REACT_APP_BASE_URL;

const AddProjectValidation = yup.object().shape({
  project_name: yup.string().required("Project Name is required"),
  project_manager: yup.string().when("$userRole", {
    is: (userRole) => {
      return userRole === Roles?.admin;
    },
    then: (schema) => schema.required("Project Manager is required"),
  }),
  project_start_date: yup.string().required("Start Date is required"),
  department: yup.string().required("Department is required"),
  members: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .min(1, "You must select at least one member")
    .required("Add Members"),
});

const ProjectForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, loading, user } = useSelector((state) => state.auth);
  const [isPrivateNotes, setIsPrivateNotes] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const location = useLocation();
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  const project = location?.state;
  useEffect(() => {
    if (project) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [project]);

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(AddProjectValidation),
    defaultValues: {
      project_name: project?.project_name ?? "",
      project_manager: project?.project_manager?._id ?? undefined,
      project_start_date: project?.project_start_date
        ? project?.project_start_date
        : formattedDate,
      department: project?.department?._id ?? "",
      members: project?.members?.map((item) => item?.value) ?? [],
      project_deadline: project?.project_deadline
        ? project?.project_deadline
        : undefined,
      projectStatus: project?.projectStatus
        ? project?.projectStatus
        : undefined,
      project_description: project?.project_description
        ? project?.project_description
        : undefined,
    },
    context: { userRole: user?.roleId },
  });

  const fetchDepartments = async () => {
    try {
      const response = await dispatch(fetchDepartment({ token })).unwrap();

      if (response?.data) {
        setDepartments(response.data.departments);
      }
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    }
  };
  const fetchEmployees = async () => {
    try {
      const response = await dispatch(fetchAllEmployees({ token })).unwrap();

      if (response?.data) {
        setEmployees(response.data.employees);
      }
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    }
  };

  useEffect(() => {
    fetchDepartments();
    fetchEmployees();
  }, []);

  const departmentOptions = departments?.map((item) => ({
    name: item?.department_name,
    value: item?._id,
  }));

  const selectedProjectManager = watch("project_manager");
  useEffect(() => {
    if (selectedProjectManager || user?.roleId === Roles?.project_manager) {
      setError("members", {
        type: "manual",
        message: "",
      });
    }
  }, [selectedProjectManager]);

  const filteredEmployees = employees?.filter((item) => {
    return item?.roleId !== Roles?.admin && item?._id !== user?._id;
  });

  const employeeOptions = filteredEmployees
    ?.filter((item) => item?._id !== selectedProjectManager)
    .map((item) => ({
      name: item?.user_name,
      value: item?._id,
    }));

  const projectManagers = employees?.filter(
    (item) => item?.roleId === Roles?.project_manager
  );

  const projectManagerOptions = projectManagers?.map((item) => ({
    name: item?.user_name,
    value: item?._id,
    avatar: item?.avatar
      ? `${baseUrl}/${item?.avatar}`
      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
  }));

  const onSubmitHandler = async (data) => {
    let payload = isEditMode
      ? {
          project_name:
            project?.project_name !== data?.project_name
              ? data?.project_name
              : undefined,
          project_manager:
            project?.project_manager?._id !== data?.project_manager
              ? data?.project_manager
              : undefined,
          project_start_date:
            project?.project_start_date !== data?.project_start_date
              ? data?.project_start_date
              : undefined,
          department:
            project?.department?._id !== data?.department
              ? data?.department
              : undefined,
          members: data?.members,
          project_deadline:
            project?.project_deadline !== data?.project_deadline
              ? data?.project_deadline
              : undefined,
          projectStatus:
            project?.projectStatus !== data?.projectStatus
              ? data?.projectStatus
              : undefined,
          project_description:
            project?.project_description !== data?.project_description
              ? data?.project_description
              : undefined,
        }
      : {
          ...data,
          project_notes: data?.project_notes
            ? { title: data?.project_notes, isPrivateNotes: isPrivateNotes }
            : undefined,
        };

    dispatch(setLoading(true));
    try {
      const apiMethod = isEditMode ? "PUT" : "POST";
      const apiUrl = isEditMode
        ? `${commonEndPoints?.PROJECT_API}/${project?._id}`
        : commonEndPoints?.PROJECT_API;
      const project_response = await apiConnector(apiMethod, apiUrl, payload, {
        Authorization: `Bearer ${token}`,
      });
      toast.success(
        isEditMode
          ? "Project updated successfully"
          : "Project created Successfully"
      );
      navigate(-1);
      reset();
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
      reset();
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="w-full border bg-custom-white rounded-md">
      <form
        className="w-full p-6 "
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="font-semibold text-custom-black">
          {isEditMode ? "Edit Project" : "Add Project"}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
          <div className="my-2 sm:my-4 relative">
            <MdOutlineEventNote className="absolute z-10 right-2 top-9" />
            <CustomInput
              name="project_name"
              label="Project Name"
              inputType="text"
              placeholder=""
              control={control}
              required={true}
              error={errors?.project_name?.message}
            />
          </div>
          {user?.roleId === Roles?.admin && (
            <div className="my-2 sm:my-4 ">
              <CustomSelect
                name="project_manager"
                label="Project Manager"
                inputType="text"
                placeholder="Select..."
                control={control}
                required={true}
                options={projectManagerOptions}
                error={errors?.project_manager?.message}
              />
            </div>
          )}
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="project_start_date"
              label="Start Date"
              inputType="date"
              placeholder=""
              control={control}
              required={true}
              error={errors?.project_start_date?.message}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="project_deadline"
              label="Due Date"
              inputType="date"
              placeholder=""
              control={control}
            />
          </div>
          <div className="my-2 sm:my-4">
            <CustomSelect
              name="department"
              label="Department"
              inputType="text"
              placeholder="Select..."
              control={control}
              required={true}
              options={departmentOptions}
              error={errors?.department?.message}
            />
          </div>
          <div
            onClick={() => {
              if (user?.roleId === Roles?.admin && !selectedProjectManager) {
                setError("members", {
                  type: "manual",
                  message: "Note:- Please Select a Project Manager First!",
                });
              }
            }}
            className="my-2 sm:my-4"
          >
            <CustomSelect
              name="members"
              label="Add Members"
              inputType="text"
              placeholder="Select..."
              control={control}
              required={true}
              multiple="true"
              options={employeeOptions}
              error={errors?.members?.message}
              isEditable={
                user?.roleId === Roles?.admin && !selectedProjectManager
              }
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="projectStatus"
              label="Status"
              inputType="text"
              placeholder="Select..."
              control={control}
              options={statusOptions}
            />
          </div>
        </div>
        <div className="my-2 sm:my-4 grid-cols-3">
          <CustomInput
            name="project_description"
            label="Project Summary"
            inputType="text-area"
            placeholder=""
            control={control}
          />
        </div>
        {!isEditMode && (
          <div className="relative my-2 sm:my-4">
            <label className="absolute right-1 -top-1 flex justify-end items-center gap-2 cursor-pointer">
              <span className="ms-3 text-sm font-medium text-gray-900">
                Private Notes
              </span>
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                onChange={() => setIsPrivateNotes(!isPrivateNotes)}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-custom-blue"></div>
            </label>

            <CustomInput
              name="project_notes"
              label="Project Notes"
              inputType="text-area"
              placeholder=""
              control={control}
            />
          </div>
        )}
        <div className="flex justify-end items-center my-10 gap-4">
          {loading ? (
            <CustomButton
              title={
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              }
              buttonType="submit"
              classname="bg-gradient-custom text-custom-white px-5 py-1 rounded-md cursor-not-allowed"
            />
          ) : (
            <CustomButton
              title={isEditMode ? "Save" : "Create"}
              buttonType="submit"
              classname="bg-gradient-custom text-custom-white px-5 py-1 rounded-md"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default ProjectForm;
