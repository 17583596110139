import React from "react";
import { Heading } from "../project";
import { dateFormatter } from "../../helper/formatDate";
import { CustomAssignees, CustomButton } from "../common";
import { useNavigate } from "react-router-dom";

const TaskDetails = ({ taskDetails }) => {
  const navigate = useNavigate();
  return (
    <div className="p-4">
      <div className="flex justify-between items-center font-semibold">
        <h1>{taskDetails?.title}</h1>
        <CustomButton
          title={"Edit Task"}
          buttonType={"button"}
          onClick={() => {
            navigate("/task/create", {
              state: { task: taskDetails },
            });
          }}
          classname="underline text-[#2E6790] px-5 py-1 rounded-md"
        />
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-4 gap-y-10 my-5">
        <Heading label="Task ID" value={taskDetails?.taskId} />
        <Heading label="Task Name" value={taskDetails?.title} />
        <Heading
          label="Project Name"
          value={taskDetails?.project?.project_name}
        />
        <Heading
          label="Completed ON"
          value={
            taskDetails?.completed_on
              ? dateFormatter(taskDetails?.completed_on)
              : "--"
          }
        />
        <Heading
          label="Estimated Time"
          value={taskDetails?.estimated_time ?? "--"}
        />
        <Heading
          label="Hours Logged"
          value={taskDetails?.hours_logged ?? "--"}
        />
        <Heading
          label="Start Date"
          value={
            taskDetails?.start_date
              ? dateFormatter(taskDetails?.start_date)
              : "--"
          }
        />
        <Heading
          label="End Date"
          value={
            taskDetails?.due_date ? dateFormatter(taskDetails?.due_date) : "--"
          }
        />
        <Heading label="Status" value={taskDetails?.status} />
        <Heading label="Priority" value={taskDetails?.priority} />
        <Heading
          label="Assignees"
          value={<CustomAssignees assignees={taskDetails?.assign_to} />}
        />
      </div>
      <div className="w-full text-justify">
        <h3 className="text-[#757575]">Description</h3>
        <span>{taskDetails?.description}</span>
      </div>
    </div>
  );
};

export default TaskDetails;
