import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setUser } from "../../redux/slice/authSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { authEndPoints } from "../../networking/Endpoints";
import Skeleton from "react-loading-skeleton";
import EditProfileSection from "./EditProfileSection";
import ChangePasswordSection from "./ChangePasswordSection";
import { ApiError } from "../../helper/ApiError";
import { toast } from "react-toastify";
const Profile = () => {
  const { token, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const GetProfileDetails = async () => {
    dispatch(setLoading(true));
    try {
      const profile_response = await apiConnector(
        "GET",
        authEndPoints?.GET_PROFILE_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      dispatch(setUser(profile_response?.data?.data));
      return profile_response;
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    GetProfileDetails();
  }, [token]);
  return loading ? (
    <div className="w-full h-full space-y-7">
      {/* Profile Section */}
      <section className="w-full h-auto p-7 bg-custom-white border border-[#DEE9F0] flex items-center gap-5 rounded-lg">
        <div className="relative">
          <Skeleton circle height={64} width={64} className="md:w-40 md:h-40" />
        </div>
        <div>
          <Skeleton width={200} height={24} className="mb-1" />
          <Skeleton width={150} height={18} />
        </div>
      </section>

      {/* Personal Details Section */}
      <section className="w-full h-auto p-7 bg-custom-white border border-[#DEE9F0] rounded-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold text-custom-black">
            Personal Details
          </h1>
          <Skeleton width={120} height={30} />
        </div>
        <div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
            <Skeleton height={30} className="my-2 sm:my-4" />
            <Skeleton height={30} className="my-2 sm:my-4" />
            <Skeleton height={30} className="my-2 sm:my-4" />
            <Skeleton height={30} className="my-2 sm:my-4" />
            <Skeleton height={30} className="my-2 sm:my-4" />
            <Skeleton height={30} className="my-2 sm:my-4" />
          </div>
        </div>
      </section>

      {/* Change Password Section */}
      <section className="w-full h-auto p-7 bg-[#ECF7FF] border border-[#D7EEFF] rounded-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold text-custom-black">
            Change Password
          </h1>
          <Skeleton width={120} height={30} />
        </div>
      </section>
    </div>
  ) : (
    <div className="w-full h-full space-y-7">
      <EditProfileSection fetchProfileDetails={GetProfileDetails} />
      <ChangePasswordSection />
    </div>
  );
};

export default Profile;
