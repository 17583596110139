import React from "react";
import { Status } from "../../networking/Constant";

const CustomStatusDropdown = ({ statusRef, handleStatusChange }) => {
  return (
    <div
      ref={statusRef}
      className="w-36 absolute top-8 left-0 mt-1 bg-white text-custom-black border rounded shadow-[2px_3px_10px_6px_#257CBB1F] z-50"
    >
      {Object.values(Status).map((statusOption) => (
        <div
          key={statusOption}
          onClick={() => handleStatusChange(statusOption)}
          className={`cursor-pointer hover:bg-gray-200 p-1 mx-3 my-1  flex justify-start items-center gap-3 rounded-md ${
            statusOption === Status?.in_progress ? "text-[#C6B30A]" : ""
          } ${statusOption === Status?.completed ? "text-[#47BA11] " : ""}${
            statusOption === Status?.cancelled ? "text-[#EF1212] " : ""
          }${statusOption === Status?.not_started ? "text-[#EF1212] " : ""}${
            statusOption === Status?.on_hold ? "text-custom-blue " : ""
          }`}
        >
          <span
            className={`w-1.5 h-1.5 rounded-full ${
              statusOption === Status?.in_progress ? "bg-[#C6B30A]" : ""
            } ${statusOption === Status?.completed ? "bg-[#47BA11] " : ""}${
              statusOption === Status?.cancelled ? "bg-[#EF1212] " : ""
            }${statusOption === Status?.not_started ? "bg-[#EF1212] " : ""}${
              statusOption === Status?.on_hold ? "bg-custom-blue " : ""
            }`}
          ></span>
          <span>{statusOption}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomStatusDropdown;
