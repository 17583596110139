import React, { useEffect, useRef, useState } from "react";
import { PiCalendarDotsLight } from "react-icons/pi";
import {
  CustomAssignees,
  CustomButton,
  CustomModal,
  CustomStatusDropdown,
} from "../common";
import { Roles, Status } from "../../networking/Constant";
import { CiEdit } from "react-icons/ci";
import { dateFormatter } from "../../helper/formatDate";
import { RiDeleteBin6Line, RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/authSlice";
import { adminEndPoints, commonEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";
import { apiConnector } from "../../networking/ApiConnector";
import { ApiError } from "../../helper/ApiError";
import useOnClickOutside from "../../helper/onClickOutside";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoSwapHorizontal } from "react-icons/io5";

const ProjectCard = ({
  _id = "",
  project_name = "",
  project_manager = {},
  department = {},
  project_id = "",
  project_description = "",
  start_date = "",
  end_date = "",
  status = "",
  assignees = [],
  onClick = () => {},
  fetchAllProjects,
}) => {
  const { token, user } = useSelector((state) => state.auth),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    statusRef = useRef(),
    [deleteProject, setDeleteProject] = useState(false),
    [statusModal, setStatusModal] = useState(false),
    [openStatus, setOpenStatus] = useState(false),
    [selectedStatus, setSelectedStatus] = useState();
  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  useOnClickOutside(statusRef, () => setOpenStatus(false));

  const updateProjectStatusHandler = async (projectId) => {
    dispatch(setLoading(true));
    try {
      const project_response = await apiConnector(
        "PUT",
        `${commonEndPoints?.PROJECT_API}/${projectId}`,
        {
          projectStatus: selectedStatus,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success("Status updated successfully");
      if (fetchAllProjects) {
        fetchAllProjects();
      }
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleStatusChange = async (newStatus) => {
    setSelectedStatus(newStatus);
    setOpenStatus(false);
    setStatusModal(true);
  };

  const deleteProjectHandler = async (projectId) => {
    dispatch(setLoading(true));
    try {
      const project_response = await apiConnector(
        "DELETE",
        `${adminEndPoints?.DELETE_PROJECT_API}/${projectId}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success(
        project_response?.data?.message ?? "Project deleted successfully"
      );
      if (fetchAllProjects) {
        fetchAllProjects();
      }
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div
      onClick={onClick}
      className="border border-[#E8F0F6] bg-custom-white p-4 rounded-lg shadow-[2px_3px_10px_6px_#257CBB0F] space-y-3 cursor-pointer"
    >
      <div className="flex justify-between items-center">
        <div
          ref={statusRef}
          onClick={(event) => {
            event.stopPropagation();
            setOpenStatus(!openStatus);
          }}
          className={`relative p-1 rounded-md flex justify-center items-center gap-1 ${
            selectedStatus === Status?.in_progress
              ? "text-[#C6B30A] bg-custom-status-in-progress"
              : ""
          } ${
            selectedStatus === Status?.completed
              ? "text-[#47BA11] bg-custom-status-completed"
              : ""
          }${
            selectedStatus === Status?.cancelled
              ? "text-[#EF1212] bg-custom-status-not-started"
              : ""
          }${
            selectedStatus === Status?.not_started
              ? "text-[#EF1212] bg-custom-status-not-started"
              : ""
          }${
            selectedStatus === Status?.on_hold
              ? "text-custom-blue bg-custom-status-on-hold"
              : ""
          }`}
        >
          {selectedStatus}
          <span>
            <MdKeyboardArrowDown size={20} />
          </span>
          {openStatus && (
            <CustomStatusDropdown
              statusRef={statusRef}
              handleStatusChange={handleStatusChange}
            />
          )}
        </div>
        {statusModal && (
          <CustomModal
            isOpen={statusModal}
            onClose={() => setStatusModal(false)}
          >
            <div className="text-center text-custom-black">
              <h2 className="text-2xl font-bold my-4">Change Status</h2>
            </div>
            <div className="w-20 h-20 bg-[#C3E6FF] p-2 rounded-full flex justify-center items-center">
              <div className="bg-custom-blue rounded-full w-16 h-16 flex justify-center items-center">
                <IoSwapHorizontal size={32} className="text-custom-white" />
              </div>
            </div>
            <div className="text-center text-[#747474]">
              <h2 className="font-medium my-4">
                are you sure you want to change status?
              </h2>
            </div>

            <div className="flex justify-end items-center gap-5 mt-5">
              <CustomButton
                title={"Cancel"}
                onClick={() => setStatusModal(false)}
                buttonType={"button"}
                classname={"px-2 py-1 bg-custom-white border rounded-md "}
              />
              <CustomButton
                title={"Continue"}
                onClick={() => {
                  updateProjectStatusHandler(_id);
                }}
                buttonType={"button"}
                classname={
                  "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                }
              />
            </div>
          </CustomModal>
        )}
        <div className="flex items-center gap-2">
          <div
            onClick={(event) => {
              event.stopPropagation();
              navigate("/project/create", {
                state: {
                  _id: _id ?? "",
                  project_name: project_name ?? "",
                  project_manager: project_manager ?? "",
                  project_start_date: start_date?.split("T")[0] ?? "",
                  project_deadline: end_date?.split("T")[0] ?? "",
                  department: department ?? "",
                  members:
                    assignees?.map((member) => ({
                      name: member?.user_name,
                      value: member._id,
                    })) ?? [],
                  projectStatus: selectedStatus ?? "",
                  project_description: project_description ?? "",
                },
              });
            }}
            className="w-7 h-7 rounded-full border text-[#626262] font-semibold flex justify-center items-center"
          >
            <CiEdit />
          </div>
          {user?.roleId === Roles?.admin &&
            (status === Status?.not_started ||
              status === Status?.cancelled) && (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  setDeleteProject(!deleteProject);
                }}
                className="w-7 h-7 rounded-full border border-[#FDDADA] bg-[#FFEDED] text-[#EF1212] font-semibold flex justify-center items-center"
              >
                <RiDeleteBin6Line />
              </div>
            )}
          {deleteProject && (
            <CustomModal
              isOpen={deleteProject}
              onClose={() => setDeleteProject(false)}
            >
              <div className="text-center text-custom-black">
                <h2 className="text-2xl font-bold my-4">Delete?</h2>
              </div>
              <div className="w-20 h-20 bg-[#FFECEC] p-2 rounded-full flex justify-center items-center">
                <div className="bg-[#FB0F0F] rounded-full w-16 h-16 flex justify-center items-center">
                  <RiDeleteBinLine size={32} className="text-custom-white" />
                </div>
              </div>
              <div className="text-center text-[#747474]">
                <h2 className="font-medium my-4">
                  Are you sure you want to delete it?
                </h2>
              </div>

              <div className="flex justify-end items-center gap-5 mt-5">
                <CustomButton
                  title={"Cancel"}
                  onClick={() => setDeleteProject(false)}
                  buttonType={"button"}
                  classname={"px-2 py-1 bg-custom-white border rounded-md "}
                />
                <CustomButton
                  title={"Delete"}
                  onClick={() => deleteProjectHandler(_id)}
                  buttonType={"button"}
                  classname={
                    "bg-[#FB0F0F] text-custom-white px-2 py-1 rounded-md"
                  }
                />
              </div>
            </CustomModal>
          )}
        </div>
      </div>
      <div>
        <h1 className={`font-medium text-custom-black`}>{project_name}</h1>
        <span className="text-[#747474] text-sm">ProjectID - {project_id}</span>
      </div>
      <div className="border border-dashed" />
      <div className="text-[#747474] text-sm line-clamp-3 text-justify">
        {project_description}
      </div>
      <div className="flex items-center gap-10 w-full sm:w-1/2 md:w-2/3">
        <div className="flex items-center gap-2">
          <PiCalendarDotsLight className="text-[#7B7F82]" size={22} />
          <span>{start_date ? dateFormatter(start_date) : "--"}</span>
        </div>
        <div className="flex items-center gap-2">
          <PiCalendarDotsLight className="text-[#7B7F82]" size={22} />
          <span>{end_date ? dateFormatter(end_date) : "--"}</span>
        </div>
      </div>
      <CustomAssignees assignees={assignees} />
    </div>
  );
};

export default ProjectCard;
