import React, { useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomButton,
  Pagination,
  TableHeader,
} from "../../common";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdOutlineFilterAlt } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../../helper/onClickOutside";
import Skeleton from "react-loading-skeleton";
import { ApiError } from "../../../helper/ApiError";
import { toast } from "react-toastify";
import CreateDesignation from "./CreateDesignation";
import { fetchDesignation } from "../../../redux/slice/designationSlice";
import { dateFormatter, timeFormatter } from "../../../helper/formatDate";

const Designation = () => {
  const actionRef = useRef(),
    dispatch = useDispatch(),
    { token } = useSelector((state) => state.auth),
    { designationLoading } = useSelector((state) => state.designation),
    [showDesignation, setShowDesignation] = useState(false),
    [designationDetails, setDesignationDetails] = useState(null),
    [designation, setDesignation] = useState([]),
    [designationTableData, setDesignationTableData] = useState([]),
    [searchString, setSearchString] = useState(""),
    [designationCount, setDesignationCount] = useState(0),
    [openDropdownIndex, setOpenDropdownIndex] = useState(null),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [currentPage, setCurrentPage] = useState(1);
  const TableHeads = [
    "S.No",
    "Department",
    "Designation",
    "Created At",
    "Last Updated At",
    "Actions",
  ];

  useOnClickOutside(actionRef, () => setOpenDropdownIndex(null));

  const fetchDesignations = async () => {
    try {
      const response = await dispatch(
        fetchDesignation({ token, currentPage, itemsPerPage })
      ).unwrap();

      if (response?.data) {
        setDesignation(response.data.designation);
        setDesignationTableData(response.data.designation);
        setDesignationCount(response.data.count_designation);
      }
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    }
  };

  useEffect(() => {
    fetchDesignations();
  }, [currentPage, itemsPerPage]);

  const filterDepartment = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filteredDepartments = designation?.filter((item) =>
        item?.department.toLowerCase().includes(val.toLowerCase())
      );
      setDesignationTableData(filteredDepartments);
    } else {
      setDesignationTableData(designation);
    }
  };

  const pageCount = Math.ceil(designationCount / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };

  return (
    <div className="w-full">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold text-lg">Designation List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          <div className="relative">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              type="text"
              placeholder="Search"
              onChange={(e) => filterDepartment(e)}
              value={searchString}
              className="border px-6 py-2 outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={<MdOutlineFilterAlt />}
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 rounded-md border "
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add Designation</span>
              </div>
            }
            buttonType="button"
            onClick={() =>
              setShowDesignation((showDesignation) => !showDesignation)
            }
            classname="bg-gradient-custom text-custom-white px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full">
        <table className="w-full border bg-custom-white rounded-lg overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {designationLoading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : designationTableData && designationTableData?.length > 0 ? (
            <>
              <tbody>
                {designationTableData?.map((item, index) => {
                  return (
                    <tr key={index} className="border-b">
                      <td className="p-3 text-sm font-medium">{index + 1}</td>
                      <td className="p-3 text-sm">
                        {item?.department
                          ? item?.department?.department_name
                          : "-"}
                      </td>
                      <td className="p-3 text-sm">
                        {item?.designation ?? "-"}
                      </td>
                      <td className="p-3 text-sm">
                        {item?.createdAt
                          ? dateFormatter(item?.createdAt)
                          : "--"}{" "}
                        {item?.createdAt
                          ? timeFormatter(item?.createdAt)
                          : "--"}
                      </td>
                      <td className="p-3 text-sm">
                        {item?.createdAt !== item?.updatedAt
                          ? dateFormatter(item?.updatedAt)
                          : "-"}{" "}
                        {item?.createdAt !== item?.updatedAt &&
                          timeFormatter(item?.updatedAt)}
                      </td>
                      <td
                        onClick={() =>
                          setOpenDropdownIndex(
                            openDropdownIndex === index ? null : index
                          )
                        }
                        className="p-3 flex justify-center items-center "
                      >
                        <HiOutlineDotsVertical />
                        {openDropdownIndex === index && (
                          <CustomActionDropDown
                            ref={actionRef}
                            buttonsDropdown={[
                              {
                                id: 1,
                                name: "Edit",
                                onClick: () => {
                                  setDesignationDetails(item);
                                  setShowDesignation(true);
                                },
                              },
                              {
                                id: 2,
                                name: "Delete",
                                onClick: () => {},
                              },
                            ]}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads?.length}
                  className="text-center font-semibold text-sm"
                >
                  Data Not Found
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                Showing{" "}
                {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}
                to {(currentPage - 1) * itemsPerPage +
                  designation?.length} from {designationCount} entries
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
      {showDesignation && (
        <CreateDesignation
          designationDetails={designationDetails}
          setDesignationDetails={setDesignationDetails}
          showDesignation={showDesignation}
          setShowDesignation={setShowDesignation}
          getDesignation={fetchDesignations}
        />
      )}
    </div>
  );
};

export default Designation;
