import React from "react";

const TableHeader = ({ TableHeads }) => {
  return (
    <tr className="text-sm sm:text-[13px] text-slate-600 bg-[#E3F0FA] font-medium">
      {TableHeads?.map((item, index) => (
        <th key={index} className="text-left p-3">
          {item}
        </th>
      ))}
    </tr>
  );
};

export default TableHeader;
