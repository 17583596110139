import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const CustomInput = ({
  name = "",
  label = "",
  inputType = "",
  control,
  required = false,
  error,
  placeholder = "",
  labelClassName = "block text-custom-black",
  classname = "",
  isEditable = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return inputType === "text-area" ? (
    <>
      <label htmlFor={name} className={labelClassName}>
        {label}
        {required && <span className="text-red-500"> *</span>}
      </label>
      <div className="relative">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value } }) => (
            <textarea
              name={name}
              required={required}
              rows={3}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={isEditable}
              className={`border px-3 py-2 outline-none w-full rounded-md resize-none text-justify ${
                isEditable ? "bg-gray-100 cursor-not-allowed" : ""
              } ${classname}`}
            />
          )}
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
      </div>
    </>
  ) : (
    <>
      <label htmlFor={name} className={labelClassName}>
        {label}
        {required && <span className="text-red-500"> *</span>}
      </label>
      <div className="relative">
        {inputType === "password" ? (
          showPassword ? (
            <MdOutlineVisibilityOff
              size={22}
              onClick={() => setShowPassword(!showPassword)}
              className="absolute text-custom-white cursor-pointer right-2 top-3"
            />
          ) : (
            <MdOutlineVisibility
              size={22}
              onClick={() => setShowPassword(!showPassword)}
              className="absolute text-custom-white cursor-pointer right-2 top-3"
            />
          )
        ) : (
          ""
        )}

        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value } }) => (
            <input
              tabIndex={0}
              name={name}
              required={required}
              placeholder={placeholder}
              type={showPassword ? "text" : inputType}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={isEditable}
              className={`border px-3 py-2 outline-none w-full rounded-md ${
                isEditable ? "bg-gray-100" : ""
              } ${classname}`}
            />
          )}
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
      </div>
    </>
  );
};

export default CustomInput;
