import React, { useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomAssignees,
  CustomButton,
  CustomInput,
  CustomModal,
  CustomSelect,
  CustomStatusDropdown,
  Pagination,
  TableHeader,
} from "../../common";
import { Roles, Status } from "../../../networking/Constant";
import { dateFormatter } from "../../../helper/formatDate";
import { IoMdSearch } from "react-icons/io";
import { MdKeyboardArrowDown, MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../../../helper/onClickOutside";
import { HiOutlineDotsVertical } from "react-icons/hi";
import AddSubTask from "./AddSubTask";
import { ApiError } from "../../../helper/ApiError";

import { apiConnector } from "../../../networking/ApiConnector";
import { commonEndPoints } from "../../../networking/Endpoints";
import { setLoading } from "../../../redux/slice/authSlice";
import { IoSwapHorizontal } from "react-icons/io5";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SubTaskView from "./SubTaskView";
const statusUpdateFormValidation = yup.object().shape({
  hours_logged_hrs: yup.string().required("Hours Logged is required"),
  completed_on: yup.string().required("Completed_on is required"),
  completed_by: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .min(1, "Completed_by is required")
    .required("Completed_by is required"),
});
const SubTask = ({ task_id = "", taskMembers = [] }) => {
  const actionRef = useRef(),
    statusRef = useRef(),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    { token, loading, user } = useSelector((state) => state.auth),
    [openAddSubTaskForm, setOpenAddSubTaskForm] = useState(false),
    [openSubTaskViewModal, setOpenSubTaskViewModal] = useState(false),
    [currentPage, setCurrentPage] = useState(1),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [openDropdownIndex, setOpenDropdownIndex] = useState(null),
    [searchString, setSearchString] = useState(""),
    [statusIndex, setStatusIndex] = useState(""),
    [openStatus, setOpenStatus] = useState(false),
    [statusModal, setStatusModal] = useState(false),
    [selectedStatus, setSelectedStatus] = useState(""),
    [subTask, setSubTask] = useState(null),
    [subTasks, setSubTasks] = useState([]),
    [tableData, setTableData] = useState([]),
    [subTaskCount, setSubTaskCount] = useState(0),
    [subTaskId, setSubTaskId] = useState("");

  const handleStatusChange = async (newStatus) => {
    setSelectedStatus(newStatus);
    setStatusModal(true);
    setOpenStatus(false);
  };
  const TableHeads = [
    "SubTask ID",
    "Name",
    "Start Date",
    "Due Date",
    "Status",
    "Action",
  ];

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(statusUpdateFormValidation) });
  useOnClickOutside(actionRef, () => setOpenDropdownIndex(null));
  useOnClickOutside(statusRef, () => setStatusIndex(null));

  const updateSubTaskStatusHandler = async (data) => {
    dispatch(setLoading(true));
    try {
      const sub_task_response = await apiConnector(
        "PUT",
        `${commonEndPoints?.SUB_TASK_API}/${subTaskId}`,
        selectedStatus === "Completed" ? data : { status: selectedStatus },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success("Status updated successfully");
      if (selectedStatus === "Completed") {
        reset({
          hours_logged_hrs: "",
          hours_logged_min: "",
          completed_on: "",
          completed_by: "",
        });
      }
      fetchSubTasks();
      setStatusModal(false);
    } catch (error) {
      const errorMessage = ApiError(error);
      <Toaster toastType="error" message={`${errorMessage}`} />;
    } finally {
      dispatch(setLoading(false));
    }
  };
  const fetchSubTasks = async () => {
    dispatch(setLoading(true));
    try {
      const sub_task_response = await apiConnector(
        "GET",
        `${commonEndPoints?.SUB_TASK_API}/${task_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_number: currentPage,
          page_size: itemsPerPage,
        }
      );
      setSubTasks(sub_task_response?.data?.data?.sub_tasks);
      setTableData(sub_task_response?.data?.data?.sub_tasks);
      setSubTaskCount(sub_task_response?.data?.data?.subtask_count);
    } catch (error) {
      const errorMessage = ApiError(error);
      <Toaster toastType="error" message={`${errorMessage}`} />;
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    fetchSubTasks();
  }, [currentPage, itemsPerPage]);

  const pageCount = Math.ceil(subTaskCount / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  const onSubmitHandler = (data) => {
    const payload = {
      status: selectedStatus,
      hours_logged:
        data?.hours_logged_hrs &&
        data?.hours_logged_min &&
        `${data?.hours_logged_hrs}:${data?.hours_logged_min}`,
      completed_on: data?.completed_on ? data?.completed_on : undefined,
    };
    updateSubTaskStatusHandler(payload);
  };
  return (
    <div className="w-full bg-custom-white rounded-md p-4">
      {openAddSubTaskForm ? (
        <AddSubTask
          taskId={task_id}
          subTask={subTask}
          taskMembers={taskMembers}
        />
      ) : (
        <>
          <section className="flex sm:flex-row flex-col justify-between items-center py-2 rounded-md">
            <h1 className="font-semibold">SubTask List</h1>
            <div className="flex flex-col md:flex-row justify-center items-center gap-4">
              <div className="relative ">
                <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
                <input
                  name="searchString"
                  label=""
                  type="text"
                  placeholder="Search"
                  // onChange={(e) => filterProject(e)}
                  // value={searchString}
                  className="bg-custom-white px-6 py-2 border outline-none rounded-md"
                />
              </div>
              <CustomButton
                title={<MdOutlineFilterAlt />}
                buttonType="submit"
                classname="bg-custom-white text-slate-400 p-2 rounded-md border"
              />
              {user?.roleId !== Roles?.employee && (
                <CustomButton
                  title={
                    <div className="flex justify-center items-center gap-2 ">
                      <FaPlusCircle />
                      <span>Add SubTask</span>
                    </div>
                  }
                  buttonType="button"
                  onClick={() => setOpenAddSubTaskForm(true)}
                  classname="bg-gradient-custom text-custom-white px-5 py-2 rounded-md"
                />
              )}
            </div>
          </section>
          <section className="w-full h-full">
            <table className="w-full border rounded-lg  overflow-auto">
              {loading ? (
                <Skeleton count={10} width={"100%"} />
              ) : tableData && tableData?.length > 0 ? (
                <>
                  <thead>
                    <TableHeader TableHeads={TableHeads} />
                  </thead>
                  <tbody>
                    {tableData?.map((item, index) => {
                      return (
                        <tr key={item?._id} className="border-b">
                          <td className="p-2 text-sm">
                            {item?.parent_task?.taskId + "." + (index + 1)}
                          </td>
                          <td className="p-2 text-sm truncate">
                            <div className="font-semibold cursor-pointer">
                              {item?.title}
                            </div>
                          </td>

                          <td className="p-2 text-sm">
                            {item?.start_date
                              ? dateFormatter(item?.start_date)
                              : "--"}
                          </td>
                          <td className="p-2 text-sm">
                            {item?.due_date
                              ? dateFormatter(item?.due_date)
                              : "--"}
                          </td>
                          <td
                            onClick={() => {
                              setStatusIndex(index);
                              setOpenStatus(!openStatus);
                              setSubTaskId(item?._id);
                            }}
                            className={`p-2 text-sm `}
                          >
                            <div
                              className={`relative p-2 cursor-pointer w-fit rounded-lg flex justify-center items-center gap-2 ${
                                item?.status === Status?.in_progress
                                  ? "text-[#C6B30A] bg-custom-status-in-progress"
                                  : ""
                              } ${
                                item?.status === Status?.completed
                                  ? "text-[#47BA11] bg-custom-status-completed"
                                  : ""
                              }${
                                item?.status === Status?.cancelled
                                  ? "text-[#EF1212] bg-custom-status-not-started"
                                  : ""
                              }${
                                item?.status === Status?.not_started
                                  ? "text-[#EF1212] bg-custom-status-not-started"
                                  : ""
                              }${
                                item?.status === Status?.on_hold
                                  ? "text-custom-blue bg-custom-status-on-hold"
                                  : ""
                              }`}
                            >
                              {item?.status ?? "--"}
                              <span>
                                <MdKeyboardArrowDown size={20} />
                              </span>
                              {openStatus && statusIndex === index && (
                                <CustomStatusDropdown
                                  statusRef={statusRef}
                                  handleStatusChange={handleStatusChange}
                                />
                              )}
                            </div>
                            {statusModal && (
                              <CustomModal
                                isOpen={statusModal}
                                onClose={() => setStatusModal(false)}
                              >
                                {selectedStatus === "Completed" ? (
                                  <form
                                    noValidate
                                    onSubmit={handleSubmit(onSubmitHandler)}
                                  >
                                    <div className="my-2 sm:my-4 ">
                                      <span>Hours Logged</span>
                                      <div className=" flex justify-start items-center gap-5">
                                        <div className=" flex justify-center items-center">
                                          <CustomInput
                                            name="hours_logged_hrs"
                                            inputType="number"
                                            placeholder=""
                                            control={control}
                                            classname="w-24"
                                          />
                                          <span>Hrs</span>
                                        </div>
                                        <div className=" flex justify-center items-center">
                                          <CustomInput
                                            name="hours_logged_min"
                                            inputType="number"
                                            placeholder=""
                                            control={control}
                                            classname="w-24"
                                          />
                                          <span>Min</span>
                                        </div>
                                      </div>
                                      {errors?.hours_logged_hrs && (
                                        <p className="text-sm text-red-500">
                                          {errors?.hours_logged_hrs?.message}
                                        </p>
                                      )}
                                    </div>
                                    <div className="my-2 sm:my-4 ">
                                      <CustomInput
                                        name="completed_on"
                                        label="Completed On"
                                        inputType="date"
                                        placeholder="Select..."
                                        control={control}
                                        required={true}
                                        error={errors?.completed_on?.message}
                                      />
                                    </div>
                                    <div className="my-2 sm:my-4 ">
                                      <CustomSelect
                                        name="completed_by"
                                        label="Completed By"
                                        inputType="text"
                                        placeholder="Select..."
                                        control={control}
                                        multiple={true}
                                        options={item?.assign_to?.map(
                                          (member) => ({
                                            name:
                                              member?._id === user?._id
                                                ? "You"
                                                : member?.user_name,
                                            value: member?._id,
                                          })
                                        )}
                                        classname="bg-custom-white"
                                      />
                                    </div>
                                    <div className="flex justify-end items-center gap-5 mt-5">
                                      <CustomButton
                                        title={"Cancel"}
                                        onClick={() => setStatusModal(false)}
                                        buttonType={"button"}
                                        classname={
                                          "px-2 py-1 bg-custom-white border rounded-md "
                                        }
                                      />
                                      <CustomButton
                                        title={"Continue"}
                                        buttonType={"submit"}
                                        classname={
                                          "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                                        }
                                      />
                                    </div>
                                  </form>
                                ) : (
                                  <>
                                    <div className="text-center text-custom-black">
                                      <h2 className="text-2xl font-bold my-4">
                                        Change Status
                                      </h2>
                                    </div>
                                    <div className="w-20 h-20 bg-[#C3E6FF] p-2 rounded-full flex justify-center items-center">
                                      <div className="bg-custom-blue rounded-full w-16 h-16 flex justify-center items-center">
                                        <IoSwapHorizontal
                                          size={32}
                                          className="text-custom-white"
                                        />
                                      </div>
                                    </div>
                                    <div className="text-center text-[#747474]">
                                      <h2 className="font-medium my-4">
                                        are you sure you want to change status?
                                      </h2>
                                    </div>

                                    <div className="flex justify-end items-center gap-5 mt-5">
                                      <CustomButton
                                        title={"Cancel"}
                                        onClick={() => setStatusModal(false)}
                                        buttonType={"button"}
                                        classname={
                                          "px-2 py-1 bg-custom-white border rounded-md "
                                        }
                                      />
                                      <CustomButton
                                        title={"Continue"}
                                        onClick={() => {
                                          updateSubTaskStatusHandler();
                                        }}
                                        buttonType={"button"}
                                        classname={
                                          "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                                        }
                                      />
                                    </div>
                                  </>
                                )}
                              </CustomModal>
                            )}
                          </td>
                          <td
                            onClick={() =>
                              setOpenDropdownIndex(
                                openDropdownIndex === index ? null : index
                              )
                            }
                            className="p-2 flex justify-center items-center cursor-pointer"
                          >
                            <HiOutlineDotsVertical />
                            {openDropdownIndex === index && (
                              <CustomActionDropDown
                                ref={actionRef}
                                onView={() => {
                                  setSubTask(item);
                                  setOpenSubTaskViewModal(true);
                                }}
                                onEdit={() => {
                                  setSubTask(item);
                                  setOpenAddSubTaskForm(true);
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan={TableHeads.length}
                      className="text-center font-semibold text-sm"
                    >
                      Data Not Found
                    </td>
                  </tr>
                </tbody>
              )}
              <tfoot className="relative">
                <tr>
                  <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                    Showing{" "}
                    {currentPage === 1
                      ? 1
                      : (currentPage - 1) * itemsPerPage + 1}{" "}
                    to {(currentPage - 1) * itemsPerPage + tableData?.length}{" "}
                    from {subTaskCount} entries
                  </td>
                  <td className="absolute right-2 top-1.5">
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageRangeDisplayed={itemsPerPage}
                      pageCount={pageCount}
                      name={"pageSize"}
                      onChange={(e) => setItemsPerPage(e?.target?.value)}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </section>
        </>
      )}
      {openSubTaskViewModal && (
        <SubTaskView
          subTask={subTask}
          openSubTaskViewModal={openSubTaskViewModal}
          setOpenSubTaskViewModal={setOpenSubTaskViewModal}
        />
      )}
    </div>
  );
};

export default SubTask;
