import React from "react";
import { CustomAssignees, CustomButton, CustomModal } from "../../common";
import { useNavigate } from "react-router-dom";
import { Heading } from "../../project";
import { dateFormatter } from "../../../helper/formatDate";

const SubTaskView = ({
  subTask,
  openSubTaskViewModal,
  setOpenSubTaskViewModal,
}) => {
  const navigate = useNavigate();
  return (
    <CustomModal
      isOpen={openSubTaskViewModal}
      onClose={() => setOpenSubTaskViewModal(false)}
      classname="w-full md:w-[60%]"
    >
      <div className="p-4">
        <div className="flex justify-between items-center font-semibold">
          <h1>{subTask?.title}</h1>
          <CustomButton
            title={"Edit SubTask"}
            buttonType={"button"}
            onClick={() => {
              navigate("/task/create", {
                state: { task: subTask },
              });
            }}
            classname="underline text-[#2E6790] px-5 py-1 rounded-md"
          />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-10 mb-5">
          <Heading label="Task ID" value={subTask?.taskId} />
          <Heading label="Task Name" value={subTask?.title} />
          <Heading
            label="Completed ON"
            value={
              subTask?.completed_on
                ? dateFormatter(subTask?.completed_on)
                : "--"
            }
          />
          <Heading
            label="Estimated Time"
            value={subTask?.estimated_time ?? "--"}
          />
          <Heading label="Hours Logged" value={subTask?.hours_logged ?? "--"} />
          <Heading
            label="Start Date"
            value={
              subTask?.start_date ? dateFormatter(subTask?.start_date) : "--"
            }
          />
          <Heading
            label="End Date"
            value={subTask?.due_date ? dateFormatter(subTask?.due_date) : "--"}
          />
          <Heading label="Status" value={subTask?.status} />
          <Heading
            label="Assignees"
            value={<CustomAssignees assignees={subTask?.assign_to} />}
          />
        </div>
        <Heading label="Description" value={subTask?.description} />
      </div>
    </CustomModal>
  );
};

export default SubTaskView;
