import React from "react";
import { Status } from "../../networking/Constant";
export default function ProjectHeading({ label = "", value = "" }) {
  return (
    <div className="flex flex-col gap-1">
      <h3 className="text-[#757575]">{label}</h3>
      <span
        className={
          label === "Status"
            ? `px-4 py-1 rounded-md w-fit ${
                value === Status?.in_progress
                  ? "text-[#C6B30A] bg-custom-status-in-progress"
                  : ""
              } ${
                value === Status?.completed
                  ? "text-[#47BA11] bg-custom-status-completed"
                  : ""
              }${
                value === Status?.cancelled
                  ? "text-[#EF1212] bg-custom-status-not-started"
                  : ""
              }${
                value === Status?.not_started
                  ? "text-[#EF1212] bg-custom-status-not-started"
                  : ""
              }${
                value === Status?.on_hold
                  ? "text-custom-blue bg-custom-status-on-hold"
                  : ""
              }`
            : "text-[#1F1F1F] font-semibold text-sm text-justify flex justify-start items-center gap-2"
        }
      >
        {label === "Priority" && value === "High" && (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.7207 1.83203V20.1654"
              stroke="#F44336"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
              fill="#F44336"
            />
            <path
              d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
              stroke="#F44336"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        {label === "Priority" && value === "Medium" && (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.7207 1.83203V20.1654"
              stroke="#2780C1"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
              fill="#2780C1"
            />
            <path
              d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
              stroke="#2780C1"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        {label === "Priority" && value === "Low" && (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.7207 1.83203V20.1654"
              stroke="#919EA7"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
              fill="#919EA7"
            />
            <path
              d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
              stroke="#919EA7"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        <span>{value}</span>
      </span>
    </div>
  );
}
