export function dateFormatter(dateString, options = {}) {
  const date = new Date(dateString);

  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const formattedDate = new Date(year, month, day);
  if (formattedDate.getTime() === today.getTime()) {
    return "Today";
  } else if (formattedDate.getTime() === yesterday.getTime()) {
    return "Yesterday";
  }

  const pad = (n) => (n < 10 ? "0" + n : n);

  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const fullMonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const format = options.format || "DD/MM/YYYY";

  return format
    .replace("DD", pad(day))
    .replace("D", day)
    .replace("MMMM", fullMonthNames[month])
    .replace("MMM", monthNames[month])
    .replace("MM", pad(month + 1))
    .replace("M", month + 1)
    .replace("YYYY", year)
    .replace("YY", String(year).slice(-2));
}

export function timeFormatter(dateString) {
  const date = new Date(dateString);

  // date.setHours(date.getHours() + 5);
  // date.setMinutes(date.getMinutes() + 30);

  let hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  const pad = (n) => (n < 10 ? "0" + n : n);

  return `${pad(hours)}:${pad(minutes)} ${ampm}`;
}

export const groupByDate = (notes) => {
  if (!notes || notes.length === 0) return {};
  const today = new Date().toLocaleDateString();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedYesterday = yesterday.toLocaleDateString();

  return notes.reduce((acc, note) => {
    const noteDate = new Date(note.createdAt).toLocaleDateString();
    let displayDate;

    if (noteDate === today) {
      displayDate = "Today";
    } else if (noteDate === formattedYesterday) {
      displayDate = "Yesterday";
    } else {
      displayDate = noteDate;
    }

    if (!acc[displayDate]) {
      acc[displayDate] = [];
    }
    acc[displayDate].push(note);
    return acc;
  }, {});
};
