import React, { useEffect, useRef, useState } from "react";
import { CustomButton, CustomInput, CustomSelect } from "../common";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiConnector } from "../../networking/ApiConnector";
import { authEndPoints } from "../../networking/Endpoints";
import { setLoading, setUser } from "../../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { CiEdit } from "react-icons/ci";
import { toast } from "react-toastify";
import { ApiError } from "../../helper/ApiError";
import { IoMdCamera } from "react-icons/io";

const baseUrl = process.env.REACT_APP_BASE_URL;

const editProfileValidation = yup.object().shape({
  mobile_number: yup
    .string()
    .matches(/^[6-9]\d{9}$/, "Please enter valid Mobile number"),
});

const genderOptions = [
  {
    name: "Male",
    value: "male",
  },
  {
    name: "Female",
    value: "female",
  },
  {
    name: "Not prefer to say",
    value: "other",
  },
];

const marriedOptions = [
  {
    name: "Married",
    value: true,
  },
  {
    name: "Unmarried",
    value: false,
  },
];

const EditProfileSection = ({ fetchProfileDetails }) => {
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [editProfileDetails, setEditProfileDetails] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [previewSource, setPreviewSource] = useState(null);
  const fileInputRef = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editProfileValidation),
    defaultValues: {
      user_name: user?.user_name ?? undefined,
      mobile_number: user?.mobile_number ? user?.mobile_number : undefined,
      gender: user?.gender ? user?.gender : undefined,
      date_of_birth: user?.date_of_birth
        ? user?.date_of_birth?.split("T")[0]
        : undefined,
      marital_status: user?.marital_status,
      address: user?.address ? user?.address : undefined,
    },
  });

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const profileUpdateHandler = async (formData) => {
    dispatch(setLoading(true));
    try {
      const update_profile_response = await apiConnector(
        "PUT",
        authEndPoints?.UPDATE_PROFILE_API,
        formData,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success(`${update_profile_response?.data?.message}`);
      if (fetchProfileDetails) {
        const res = await fetchProfileDetails();
        dispatch(setUser(res?.data?.data));
      }
      setEditProfileDetails(false);
    } catch (error) {
      const errorMessage = ApiError(error);
      toast.error(`${errorMessage}`);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (imageFile) {
      const formData = new FormData();
      formData.append("avatar", imageFile);
      profileUpdateHandler(formData);
    }
  }, [imageFile]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        toast.error("File size should be less than 2MB.");
        return;
      }
      setImageFile(file);
      previewFile(file);
    }
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  useEffect(() => {
    if (imageFile) {
      previewFile(imageFile);
    }
  }, [imageFile]);

  const editProfileDetailsHandler = async (data) => {
    const formData = new FormData();
    if (user && data) {
      if (user?.gender !== data?.gender)
        formData.append("gender", data?.gender);
      if (user?.date_of_birth?.split("T")[0] !== data?.date_of_birth)
        formData.append("date_of_birth", data?.date_of_birth);
      if (user?.address !== data?.address)
        formData.append("address", data?.address);
      if (user?.user_name !== data?.user_name)
        formData.append("user_name", data?.user_name);
      if (user?.mobile_number !== data?.mobile_number)
        formData.append("mobile_number", data?.mobile_number);
      if (user?.marital_status !== data?.marital_status)
        formData.append("marital_status", data?.marital_status);
    }
    profileUpdateHandler(formData);
  };

  return (
    <>
      <section className="w-full h-auto p-7 bg-custom-white border border-[#DEE9F0] flex justify-between items-center gap-5 rounded-lg">
        <div className="flex items-center gap-5">
          <div className="relative">
            <img
              src={
                previewSource
                  ? previewSource
                  : user?.avatar
                  ? `${baseUrl}/${user?.avatar}`
                  : `https://api.dicebear.com/5.x/initials/svg?seed=${user?.user_name}`
              }
              alt={user?.user_name ?? "Profile Image"}
              loading="lazy"
              className="w-16 h-16 md:w-40 md:h-40 rounded-full"
            />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
              accept="image/png, image/gif, image/jpeg"
            />
            <div
              onClick={handleClick}
              className="absolute right-0 bottom-0 md:right-2 md:bottom-3 border border-[#A4B5C2] bg-custom-white w-5 h-5 md:w-7 md:h-7 flex justify-center items-center rounded-full text-slate-500 cursor-pointer"
            >
              <IoMdCamera size={22} />
            </div>
          </div>
          <div>
            <span className="block text-xl font-semibold">
              {user?.user_name}{" "}
              {user?.designation && (
                <span className=" text-xs text-slate-400">
                  ({user?.designation?.designation})
                </span>
              )}
            </span>

            <span className="text-base block font-normal text-[#515557]">
              {user?.email}
            </span>
          </div>
        </div>
      </section>
      <section className="w-full h-auto p-7 bg-custom-white border border-[#DEE9F0] rounded-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold text-custom-black">
            Personal Details
          </h1>
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 border border-[#B4D2E8] px-2 py-1 text-[#96A7B4] rounded-md">
                <CiEdit size={22} />
                <span>Edit</span>
              </div>
            }
            buttonType={"button"}
            onClick={() => setEditProfileDetails(!editProfileDetails)}
          />
        </div>
        <form noValidate onSubmit={handleSubmit(editProfileDetailsHandler)}>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
            <div className="my-2 sm:my-4">
              <CustomInput
                name="user_name"
                label="Username"
                inputType="text"
                placeholder=""
                control={control}
                isEditable={editProfileDetails}
              />
            </div>

            <div className="my-2 sm:my-4 ">
              <CustomInput
                name="mobile_number"
                label="Mobile Number"
                inputType="number"
                placeholder=""
                control={control}
                isEditable={editProfileDetails}
                error={errors?.mobile_number?.message}
              />
            </div>
            <div className="my-2 sm:my-4 ">
              <CustomSelect
                name="gender"
                label="Gender"
                inputType="text"
                placeholder="Select..."
                control={control}
                options={genderOptions}
                isEditable={editProfileDetails}
              />
            </div>
            <div className="my-2 sm:my-4 ">
              <CustomSelect
                name="marital_status"
                label="Married Status"
                inputType="text"
                placeholder="Select..."
                control={control}
                options={marriedOptions}
                isEditable={editProfileDetails}
              />
            </div>
            <div className="my-2 sm:my-4 ">
              <CustomInput
                name="date_of_birth"
                label="DOB"
                inputType="date"
                placeholder=""
                control={control}
                isEditable={editProfileDetails}
              />
            </div>
            <div className="my-2 sm:my-4 ">
              <CustomInput
                name="address"
                label="Address"
                inputType="text"
                placeholder=""
                control={control}
                isEditable={editProfileDetails}
              />
            </div>
          </div>
          {!editProfileDetails && (
            <div className="flex justify-end items-center my-10 gap-4">
              <CustomButton
                title={"Cancel"}
                buttonType="button"
                onClick={() => {
                  setEditProfileDetails(true);
                }}
                classname="text-[#5A6872] border border-[#5A6872]  px-5 py-1 rounded-md"
              />
              <CustomButton
                title={"Save"}
                buttonType="submit"
                classname="bg-gradient-custom text-custom-white px-5 py-1 rounded-md"
              />
            </div>
          )}
        </form>
      </section>
    </>
  );
};

export default EditProfileSection;
