import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const CustomChart = ({ chartType = "", labels = [], data = [] }) => {
  const chartRef = useRef(null);
  let chartInstance = null;
  const generateRandomColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const color = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)})`;
      colors.push(color);
    }
    return colors;
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    // Initialize the chart
    chartInstance = new Chart(ctx, {
      type: chartType || "pie",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            backgroundColor: generateRandomColors(data?.length),
            tension: 0.4,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "left",
          },
        },
      },
    });

    // Cleanup chart instance on unmount
    return () => {
      chartInstance.destroy();
    };
  }, [data, chartType, labels]);

  return <canvas ref={chartRef}></canvas>;
};

export default CustomChart;
