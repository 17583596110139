const BASE_URL = process.env.REACT_APP_BASE_URL;

export const authEndPoints = {
  POST_LOGIN_API: BASE_URL + "/auth/login",
  CREATE_EMPLOYEE_API: BASE_URL + "/auth/create",
  GET_PROFILE_API: BASE_URL + "/auth/profile",
  UPDATE_PROFILE_API: BASE_URL + "/auth/updateProfile",
  CHANGE_PASSWORD_API: BASE_URL + "/auth/changePassword",
};

export const commonEndPoints = {
  GET_ALL_EMPLOYEE_ADMIN_API: BASE_URL + "/common/employee/",
  GET_SINGLE_EMPLOYEE_DETAILS_API: BASE_URL + "/common/employee",
  GET_DEPARTMENT_API: BASE_URL + "/common/department",
  PROJECT_API: BASE_URL + "/common/project",
  ADD_NOTES_API: BASE_URL + "/common/add_notes",
  GET_PRIVATE_NOTES_API: BASE_URL + "/common/private_notes",
  UPDATE_NOTES_API: BASE_URL + "/common/notes",
  TASK_API: BASE_URL + "/common/task",
  GET_TEAM_TASK_API: BASE_URL + "/common/team_task",
  SUB_TASK_API: BASE_URL + "/common/sub_task",
  COMMENT_API: BASE_URL + "/common/comment",
  GET_DESIGNATION_API: BASE_URL + "/common/designation",
  GET_OFFICES_API: BASE_URL + "/common/offices",
  GET_PROJECT_OVERVIEW: BASE_URL + "/common/project/dashboard",
};

export const superAdminEndPoints = {
  CREATE_ADMIN_BY_SUPER_ADMIN_API: BASE_URL + "/super_admin/create",
  GET_ALL_COMPANIES_API: BASE_URL + "/super_admin/company",
};

export const adminEndPoints = {
  CREATE_DEPARTMENT_API: BASE_URL + "/admin/department/create",
  UPDATE_DEPARTMENT_API: BASE_URL + "/admin/department",
  DELETE_PROJECT_API: BASE_URL + "/admin/project",
  CREATE_DESIGNATION_API: BASE_URL + "/admin/designation",
  OFFICE_LOCATION_API: BASE_URL + "/admin/officelocation",
};
