import React from "react";
import { GrView } from "react-icons/gr";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

const CustomActionDropDown = React.forwardRef(
  ({ buttonsDropdown = [] }, ref) => {
    return (
      <div className="relative top-5" ref={ref}>
        <div className="absolute -right-2 z-20 -top-1 bg-gradient-custom-light-blue w-7 h-7 rotate-45" />
        <div className="absolute -right-3 p-4 w-fit  rounded-md  z-50  bg-gradient-custom-light-blue flex flex-col justify-center items-start gap-3">
          {buttonsDropdown?.map((button) => (
            <button
              key={button?.id}
              onClick={button?.onClick}
              className={`w-full flex justify-start items-center gap-3 px-2 py-1 rounded-md hover:bg-slate-300 ${
                button?.name === "Delete" &&
                "hover:bg-red-500 hover:text-custom-white "
              }`}
            >
              {button?.name === "View" && (
                <GrView className="w-6 h-6 rounded-full bg-[#C8E6FC] text-custom-blue p-1" />
              )}
              {button?.name === "Edit" && (
                <MdOutlineEdit className="w-6 h-6 rounded-full bg-[#C8E6FC] text-custom-blue p-1" />
              )}
              {button?.name === "Delete" && (
                <RiDeleteBin6Line className="w-6 h-6 rounded-full bg-[#ffbfbf] text-red-500 p-1" />
              )}
              <span className="cursor-pointer">{button?.name}</span>
            </button>
          ))}
          {/* <button
            onClick={onEdit}
            className="w-full flex items-center gap-4 px-2 py-1 rounded-md hover:bg-slate-300"
          >
            <MdOutlineEdit className="w-6 h-6 rounded-full bg-[#C8E6FC] text-custom-blue p-1" />
            <span className="cursor-pointer">Edit</span>
          </button>
          <button
            onClick={onDelete}
            className="w-full flex items-center gap-4 px-2 py-1 rounded-md hover:bg-slate-300"
          >
            <RiDeleteBin6Line className="w-6 h-6 rounded-full text-red-500 bg-[#f8c9c9] p-1" />
            <span className="cursor-pointer">Delete</span>
          </button> */}
        </div>
      </div>
    );
  }
);
export default CustomActionDropDown;
